import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppURL } from './app.url';
import { AuthGuard } from './guards/auth.guard';
import { NotfoundComponent } from './modules/exception/notfound/notfound.component';

const routes: Routes = [
  // Redirect signin first
  { path: '', redirectTo: AppURL.line, pathMatch: 'full' },

  // Account routing
  // {
  //   path: AppURL.account,
  //   loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  // },
  // // Show Tv
  // {
  //   path: AppURL.tv32,
  //   loadChildren: () => import('./bookingTV/booking-tv.module').then(m => m.BookingTVModule)
  // },
  // //Show Menu
  // {
  //   path: AppURL.menulist,
  //   loadChildren: () => import('./menu/menu-list.module').then(m => m.MenuListModule)
  // },
  // // Authen routing
  // {
  //   path: AppURL.authen,
  //   loadChildren: () => import('./modules/authen/authen.module').then(m => m.AuthenModule),
  //   canActivateChild: [AuthGuard],
  // },

  {
    path: AppURL.line,
    loadChildren: () => import('./modules/line/line.module').then(m => m.LineModule)
  },
  // {
  //   path: AppURL.line,
  //   loadChildren: () => import('./modules/line/line.module').then(m => m.LineModule)
  // },

  // Another routing
  { path: '**', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
