import { environment } from 'src/environments/environment';
import { AppURL } from '../app.url';
import { BookingTVURL } from '../bookingTV/booking-tv.url';
import { MenulistURL } from '../menu/menu-list.url';
import { AccountURL } from '../modules/account/account.url';
import { AuthenURL } from '../modules/authen/authen.url';
import { BookingURL } from '../modules/booking/booking.url';
import { LineURL } from '../modules/line/line.url';
import { TableURL } from '../modules/table/table.url';
import { UserURL } from '../modules/user/user.url';

export class AppConfig {
  /** origin url */
  originURL = environment.origin;

  /**  root url */
  AppURL = AppURL;

  /** account url */
  AccountURL = AccountURL;

  /** account url */
  AuthenURL = AuthenURL;

  /** booking url */
  BookingURL = BookingURL;

  /** table url */
  TableURL = TableURL;

  /** user url */
  UserURL = UserURL;

  /** line url */
  LineURL = LineURL;
  
  //** TV URL */
  BookingTV = BookingTVURL;

  //**menu list */
  Menulist = MenulistURL;
}
