export const LineURL = {
  changeAppointment: 'change-Appointment',
  changeAppointmentSuccessful: 'change-Appointment-Successful',
  changeAppointmentContact: 'change-Appointment-Contact',
  bookingComplete: 'booking-Complete',
  verifyIdentityOTP: 'verify-Identity-OTP',
  signup: 'signup',
  profile: 'profile',
  updating: 'updating'
};
