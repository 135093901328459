/** root url */
export const AppURL = {
    account: 'account',
    authen: 'authen',
    line: 'line',
    reserve: 'reserve',
    booking: 'booking',
    table: 'table',
    time: 'time',
    tv32: 'tv32',
    menulist: 'menulist',
    setting: 'setting',
    tax_invoice: 'tax-invoice',
};